import { Button, Text, Title } from "@mantine/core";

import React, { useCallback, useState } from "react";

import { css } from "@emotion/react";

import { FlexCol } from "../FlexCol";
import { BaseModal } from "./BaseModal";

const LOCAL_STORAGE_KEY = "welcomeDismiss";
export const WelcomeModal: React.FC = () => {
  const [show, setShow] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEY) === null,
  );

  const dismiss = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, "true");
    setShow(false);
  }, []);

  return (
    <BaseModal isOpen={show} onRequestClose={dismiss}>
      <FlexCol
        css={css`
          align-items: center;
          padding: 100px;
        `}
      >
        <Title order={2} mb={12}>
          Happy Valentine's Day!
        </Title>
        <Text
          css={css`
            margin-bottom: 42px;
            text-align: center;
          `}
        >
          Please enjoy the newest edition of Postcards for Van.
          <br />
          Built with ❤️ by Brian.
        </Text>
        <Button
          css={css`
            cursor: url("/assets/cursor_pointer.png"), pointer;
          `}
          color="pink"
          onClick={dismiss}
        >
          Show me the goods!
        </Button>
      </FlexCol>
    </BaseModal>
  );
};
