import { Card, Image, Text, Title } from "@mantine/core";

import React, { useState } from "react";

import { css } from "@emotion/react";

import { FlexRow } from "../../components/FlexRow";
import { BaseModal } from "../../components/modals/BaseModal";
import { QuestionModal } from "../../components/modals/QuestionModal";
import { CardInfo } from "./cards";

const tryAgainReplies = [
  "Ooh, so close! Try again.",
  "Nope, try again.",
  "Not quite. Try again.",
  "You're almost there! Try again.",
  "Don't give up! Try again.",
];

export const Postcard: React.FC<{ card: CardInfo }> = ({ card }) => {
  const { image, title, question, answer, description } = card;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showQuestionModal, setShowQuestionModal] = useState<boolean>(false);
  return (
    <React.Fragment>
      <Card
        css={css`
          padding: 0px;
          height: 440px;
          width: 800px;
          cursor: url("/assets/cursor_pointer.png"), pointer;
          border: 1px solid black;
          :hover {
            transform: translate(0px, -4px);
          }
        `}
        shadow="lg"
        radius="md"
        withBorder
        onClick={() => {
          setShowQuestionModal(true);
        }}
      >
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            justify-content: center;
            background: url(${image});
            filter: blur(18px);
            -webkit-filter: blur(18px);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          `}
        />
        <FlexRow
          css={css`
            justify-content: center;
          `}
        >
          <Image height={440} width={300} src={image} />
        </FlexRow>
      </Card>
      <BaseModal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <FlexRow
          css={css`
            gap: 32px;
          `}
        >
          <Image height={440} width={300} src={image} />
          <div>
            <FlexRow
              css={css`
                justify-content: space-between;
                align-items: center;
                margin-bottom: 24px;
              `}
            >
              <Title order={2}>{title}</Title>
              <Image src="/assets/stamp.png" height={100} width={100} />
            </FlexRow>
            <Text>{description}</Text>
          </div>
        </FlexRow>
      </BaseModal>
      <QuestionModal
        show={showQuestionModal}
        onRequestClose={() => setShowQuestionModal(false)}
        question={question}
        answer={answer}
        onCorrect={() => {
          setShowQuestionModal(false);
          setShowModal(true);
        }}
      />
    </React.Fragment>
  );
};
