import React, { PropsWithChildren } from "react";
import Modal from "react-modal";

export const BaseModal: React.FC<Modal.Props & PropsWithChildren> = ({
  style,
  children,
  ...props
}) => {
  let content = {};
  let overlay = {};
  let restStyle = {};
  if (style) {
    const {
      content: contentOverride,
      overlay: overlayOverride,
      ...restStyleOverride
    } = style;
    if (contentOverride) {
      content = contentOverride;
    }
    if (overlayOverride) {
      overlay = overlayOverride;
    }
    restStyle = restStyleOverride;
  }
  return (
    <Modal
      {...props}
      style={{
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          transition: "all 300ms ease",
          border: "1px solid black",
          borderRadius: "8px",
          height: 440,
          width: 800,
          ...content,
        },
        overlay: {
          background: "none",
          backdropFilter: "blur(5px)",
          WebkitBackdropFilter: "blur(5px)",
          zIndex: 3,
          ...overlay,
        },
        ...restStyle,
      }}
    >
      {children}
    </Modal>
  );
};
