import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Global, Theme, ThemeProvider, css } from "@emotion/react";

import { App } from "./App";
import { theme } from "./themes";

const queryClient = new QueryClient();

export const AppWithProviders: React.FC = () => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={theme}>
          <Global styles={GlobalStyles} />
          <App />
          <ToastContainer />
        </ThemeProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

const GlobalStyles = (theme: Theme) => css`
  html,
  body {
    min-width: 990px;
    background: url("/assets/bg.jpeg");
  }
  body {
    margin: 0;
    max-width: 100%;
    min-height: 100vh;
    #approot {
      overflow-x: hidden;
    }
    font-family: "Poppins", sans-serif;
    cursor: url("/assets/cursor_auto.png"), auto;
  }

  // react-toastify
  :root {
    --toastify-color: ${theme.colors.grey[800]};
    --toastify-color-info: ${theme.colors.blue.primary};
    --toastify-color-success: ${theme.colors.green.primary};
    --toastify-color-error: ${theme.colors.red.primary};
    --toastify-color-warning: ${theme.colors.yellow.primary};

    --toastify-icon-color-info: ${theme.colors.blue.primary};
    --toastify-icon-color-success: ${theme.colors.green.primary};
    --toastify-icon-color-error: ${theme.colors.red.primary};
    --toastify-icon-color-warning: ${theme.colors.yellow.primary};

    --toastify-font-family: Roboto Mono, sans-serif;
    --toastify-color-dark: ${theme.colors.grey[800]};
    --toastify-toast-background: ${theme.colors.grey[800]};
  }
  .Toastify__toast {
    border: 1px solid ${theme.colors.grey[600]};
    border-radius: 0px;
  }
`;
