import { Theme } from "@emotion/react";

export const theme: Theme = {
  colors: {
    // https://coolors.co/palette/f94144-f3722c-f8961e-f9844a-f9c74f-90be6d-43aa8b-4d908e-577590-277da1
    red: {
      primary: "#f94144",
    },
    blue: {
      primary: "#277da1",
    },
    green: {
      primary: "#90be6d",
    },
    yellow: {
      primary: "#f9c74f",
    },
    // https://coolors.co/palette/f8f9fa-e9ecef-dee2e6-ced4da-adb5bd-6c757d-495057-343a40-212529
    grey: {
      primary: "#ced4da",
      100: "#f8f9fa",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#6c757d",
      700: "#495057",
      800: "#343a40",
      900: "#212529",
    },
  },
};
