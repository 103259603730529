export type CardInfo = {
  image: string;
  title: string;
  question: string;
  answer: string;
  description: string;
};

export const cards: CardInfo[] = [
  {
    image: "/assets/monterey.JPG",
    title: "California",
    question: "Where did we go camping?",
    answer: "big sur",
    description:
      "Hello from California! Our trip started in SF where we biked around the city. Remember the exercise machines? Then, we crashed at Jeff's and hung out with Ace, a good neko. We borrowed Jeff's Tesla to Santa Cruz where you were my #1 race supporter. After my race, I was able to take you camping for the first time and make a 🔥. Finally, we went back to Jeff's, and got in a Model Y Uber to learn all about building a Taxi empire. Finally, we got to spend time with your Aunt and Tom who were both very nice! I had a lot of fun on this trip, I hope our future trips will be just as good!",
  },
  {
    image: "/assets/barca.JPG",
    title: "Barcelona, Spain",
    question: "What drink took you out of commision?",
    answer: "sangria",
    description:
      "Hola from Barcelona! This is the first time we ever went on a trip overseas. After we checked into the hotel, we got to eat some foreign McDonalds (delicious!). Besides the conference, we got to explore Park Guell, Sagrada Familia, and a bunch of Gaudi stuff. We ate plenty of Paella and seafood. The weather was absolutely perfect the entire trip. Let's go back again some time!",
  },
  {
    image: "/assets/disney.JPG",
    title: "Disney World",
    question: "Which ride did you become a lost child on?",
    answer: "avatar",
    description:
      "These FWF are taking up too much space! Not sure I'd go again, but we definitely made the most out of the trip this time. We hit up every park, balled out on food, and did the rides we liked multiple times! We should bring our future kids (which we will have) here one day!",
  },
  {
    image: "/assets/ki.JPG",
    title: "Ki Sushi",
    question: "Best nigri?",
    answer: "toro",
    description:
      "First Omakase together! And hopefully more Omakase for the years to come 😋🍣.",
  },
  {
    image: "/assets/berkeley.JPG",
    title: "Berkeley, CA",
    question: "Street name we lived on in Berkeley?",
    answer: "bancroft",
    description:
      "I just thought this pic was cool. Berkeley's pretty nice, right? Could be cool to live here one day.",
  },
  {
    image: "/assets/tahoe.JPG",
    title: "Tahoe, NV",
    question: "Which ski resort?",
    answer: "heavenly",
    description:
      "Best Chipotle ever post-ski? Possibly. I hope you enjoyed me dragging you to Tahoe. We really ought to do more skiing together!",
  },
  {
    image: "/assets/rad.JPG",
    title: "Palo Alto, CA",
    question: "Best e-bike brand? (3 letters)",
    answer: "rad",
    description:
      "Looks like you're brave enough to follow me up giant mountains! I'll be getting you an e-bike if we move out of the city and live together so we can have unlimited bike adventures 🚴‍♀️🏔",
  },
  {
    image: "/assets/brooklyn.PNG",
    title: "Brooklyn, NY",
    question: "APT #",
    answer: "28h",
    description:
      "Thank you for housing the homeless here in your Brooklyn apartment! I am immensely grateful to you and your family for letting me stay with you in NYC. I am also thankful that I got to finally spend time with you short-distance. Even though you abandon me sometimes and call me out for being stinky or messy, I think we are still a good team! Just writing this in MD makes me miss you lots, and I can't wait to keep spending more time with you in the years to come <3. I think this photo was taken so we can show our future kids that we were once cool :)",
  },
];
