import { Text, TextInput, Title } from "@mantine/core";

import React, { useRef, useState } from "react";

import { css } from "@emotion/react";

import { FlexRow } from "../../components/FlexRow";
import { WelcomeModal } from "../../components/modals/WelcomeModal";
import { DefaultContainer } from "../../layout/DefaultContainer";
import { DefaultWrapper } from "../../layout/DefaultWrapper";
import { Postcard } from "./Postcard";
import { cards } from "./cards";

enum Stage {
  START,
  GUIDE,
  POSTCARDS,
}

const HomeInner: React.FC = () => {
  const [stage, setStage] = useState<number>(Stage.START);
  const passwordRef1 = useRef<HTMLInputElement>(null);
  const passwordRef2 = useRef<HTMLInputElement>(null);

  if (stage === Stage.START) {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (passwordRef1.current?.value.toLowerCase() === "vanessa lu") {
            setStage(Stage.GUIDE);
          } else {
            alert(
              "Sorry, doesn't look like you're on the list. The rest of this site is VIP-access only. Maybe next time!",
            );
          }
        }}
      >
        <Text
          css={css`
            margin-bottom: 8px;
          `}
          fz="md"
        >
          Please enter your first and last name
        </Text>
        <TextInput
          ref={passwordRef1}
          css={css`
            width: 500px;
          `}
          autoFocus
        />
      </form>
    );
  } else if (stage === Stage.GUIDE) {
    return (
      <div
        css={css`
          max-width: 800px;
        `}
      >
        <Text
          css={css`
            margin-bottom: 8px;
          `}
          fz="md"
        >
          Welcome, Vanessa! In case you didn't know, you're a VIP guest here at
          Postcards for Van.
          <br />
          <br />
          Before we get started, we want to make sure you have the best
          experience here at Postcards. We will be providing a dedicated tour
          guide, Brian Li, to help guide you through this experience.{" "}
          <strong>Please give him a call via Google Meet to move on.</strong>
        </Text>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (
              passwordRef2.current?.value.toLowerCase() === "valentines2023"
            ) {
              setStage(Stage.POSTCARDS);
            }
          }}
        >
          <TextInput ref={passwordRef2} autoFocus />
        </form>
      </div>
    );
  } else if (stage === Stage.POSTCARDS) {
    return (
      <>
        <FlexRow
          css={css`
            flex-wrap: wrap;
          `}
        >
          {cards.map((card, i) => (
            <div
              css={css`
                margin-bottom: 32px;
              `}
            >
              <Postcard key={i} card={card} />
            </div>
          ))}
        </FlexRow>
        <WelcomeModal />
      </>
    );
  }

  return <div>No stage?</div>;
};

export const Home: React.FC = () => {
  return (
    <DefaultWrapper>
      <DefaultContainer
        css={css`
          margin-top: 32px;
          * {
            transition: 300ms ease-in-out all;
          }
        `}
      >
        <Title
          css={css`
            margin-bottom: 32px;
            font-family: "Pacifico", cursive;
            color: #aa336a;
          `}
          order={1}
        >
          Postcards for Van
        </Title>
        <HomeInner />
      </DefaultContainer>
    </DefaultWrapper>
  );
};
