import { Button, Input, Text, Title } from "@mantine/core";

import React, { useRef, useState } from "react";

import { css } from "@emotion/react";

import { FlexCol } from "../FlexCol";
import { BaseModal } from "./BaseModal";

const tryAgainReplies = [
  "Ooh, so close! Try again.",
  "Nope, try again.",
  "Not quite. Try again.",
];

export const QuestionModal: React.FC<{
  show: boolean;
  onRequestClose: () => void;
  question: string;
  answer: string;
  onCorrect: () => void;
}> = ({ show, onRequestClose, question, answer, onCorrect }) => {
  const [error, setError] = useState<string | null>(null);
  const answerRef = useRef<HTMLInputElement>(null);
  return (
    <BaseModal
      isOpen={show}
      onRequestClose={() => {
        setError(undefined)
        onRequestClose();
      }}
    >
      <FlexCol
        css={css`
          align-items: center;
          padding: 100px;
        `}
      >
        <Title order={4} mb={12}>
          {question}
        </Title>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (answerRef.current?.value.toLowerCase() === answer) {
              onCorrect();
            } else {
              setError(
                tryAgainReplies[
                  Math.floor(Math.random() * tryAgainReplies.length)
                ],
              );
            }
          }}
        >
          <Input
            ref={answerRef}
            type="text"
            placeholder="Enter your answer here"
            mb={12}
            autoFocus
          />
        </form>
        <Button
          css={css`
            cursor: url("/assets/cursor_pointer.png"), pointer;
          `}
          color="pink"
          type="submit"
        >
          Answer
        </Button>
        {error && (
          <Text mt={12} color="red">
            {error}
          </Text>
        )}
      </FlexCol>
    </BaseModal>
  );
};
